import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { ErrorPanel } from 'components/StatusPanel';

export const StyledRichText = styled(RichText)`
  margin: ${spacing(4)} 0;
  h2 {
    ${fonts.headingSmall};
  }
  h3 {
    ${fonts.headingXSmall};
  }
  p {
    ${fonts.paragraphLarge}
  }
  a {
    ${fonts.paragraphLarge};
  }
`;

export const ErrorPanelWithMargin = styled(ErrorPanel)`
  margin: ${spacing(-2)} 0 ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;
