import {
  dateIsInThePast,
  dateValueToISODateString,
  localDateToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import quoteClient, { QuoteSearchRequest } from 'apiHelpers/quoteClient';
import { QuoteExpiredError } from 'businessLogic/errors/isQuoteExpiredError';
import { navigate } from 'gatsby';
import { CoverLevel } from 'helpers/businessConstants';
import { getPetApiRecaptchaToken } from 'helpers/reCaptchaHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import useLoadingState from 'helpers/useLoadingState';
import { useRetrieveQuoteDetails } from 'state/formData/retrieveQuote';
import { useInitialiseQuote } from 'state/quote/loadQuoteHelper';
import { hasUserSelectedCover } from './bundleCoverMapping';

type QuoteRetrievers = {
  retrieveQuoteByInputReferenceAndNavigate: () => Promise<void>;
  retrieveLatestQuoteAndNavigate: () => Promise<void>;
};

const useRetrieveQuote = (): QuoteRetrievers => {
  const [
    {
      customerDob,
      customerLastName,
      customerFirstName,
      customerPostcode,
      quoteNumber: inputQuoteNumber,
    },
  ] = useRetrieveQuoteDetails();
  const { isLoading, withLoadingState } = useLoadingState();

  const disableDateChecks = useDisableDateChecks();
  const initialiseQuote = useInitialiseQuote();

  const retrieveQuoteByReferenceAndNavigate = async (
    postcode: string,
    quoteNumber: string
  ): Promise<void> => {
    const recatchaToken = await withLoadingState(getPetApiRecaptchaToken);
    if (!isLoading) {
      const quote = await quoteClient.retrieve({
        postcode,
        quoteNumber,
        disableDateChecks,
        includePastCoverStartDates: true,
        recaptchaToken: recatchaToken,
      });

      const isAccidentOnlyQuote = quote.petInfos.find(
        (bundle) => bundle.coverLevelRequired === CoverLevel.ACCIDENT_AND_INJURY
      );

      if (isAccidentOnlyQuote) {
        throw new Error('Detected AO Quote!');
      }

      const shouldRedirectToConfirmCoverStartDate =
        quote.policyInfo?.coverStartDate &&
        dateIsInThePast(new Date(quote.policyInfo?.coverStartDate));

      if (shouldRedirectToConfirmCoverStartDate) {
        quote.policyInfo.coverStartDate = localDateToUtcDate(new Date()).toISOString();
      }

      await initialiseQuote(quote, hasUserSelectedCover(quote));

      if (shouldRedirectToConfirmCoverStartDate) {
        navigate(quoteAndBuyRoutes.confirmCoverStartDate);
      } else {
        navigate(quoteAndBuyRoutes.quoteSummary);
      }
    }
  };

  const retrieveLatestQuoteAndNavigate = async (): Promise<void> => {
    const searchRequest: QuoteSearchRequest = {
      firstForename: customerFirstName,
      lastname: customerLastName,
      birthdate: dateValueToISODateString(customerDob) || '',
      postcode: customerPostcode,
    };
    const quoteInfos = await quoteClient.search(searchRequest);
    if (quoteInfos.length === 0) {
      // The search endpoint returns an empty array if all associated quotes are expired
      throw QuoteExpiredError;
    }

    const latestQuoteInfo = quoteInfos.reduce((latestFoundQuoteInfo, quoteInfo) =>
      new Date(latestFoundQuoteInfo.quoteStartDate) > new Date(quoteInfo.quoteStartDate)
        ? latestFoundQuoteInfo
        : quoteInfo
    );

    await retrieveQuoteByReferenceAndNavigate(
      customerPostcode,
      latestQuoteInfo.quoteNumber
    );
  };

  const retrieveQuoteByInputReferenceAndNavigate = async (): Promise<void> => {
    await retrieveQuoteByReferenceAndNavigate(customerPostcode, inputQuoteNumber);
  };

  return { retrieveQuoteByInputReferenceAndNavigate, retrieveLatestQuoteAndNavigate };
};

export default useRetrieveQuote;
