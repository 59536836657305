import {
  ErrorPanel,
  InfoPanel,
  SuccessPanel,
  WarningPanel,
} from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';

const statusPanelStyling = css`
  display: flex;
  align-items: center;
  ${fonts.paragraph}
  margin: 0 0 ${spacing(2)};
  display: flex;
`;

export const StyledSuccessPanel = styled(SuccessPanel)`
  ${statusPanelStyling}
`;

export const StyledWarningPanel = styled(WarningPanel)`
  ${statusPanelStyling}
`;

export const StyledErrorPanel = styled(ErrorPanel)`
  ${statusPanelStyling}
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(2)};
  margin-bottom: auto;
`;

export const StyledInfoPanel = styled(InfoPanel)`
  ${statusPanelStyling}
  && {
    background: white;
  }
`;
